<template>
  <v-container>
    <v-subheader>
      <v-btn
        icon
        small
        to="/"
      >
        <v-icon small>
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <strong class="ml-2">VIP Club</strong>
    </v-subheader>

    <v-row dense>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          flat
          dark
          class="rounded-lg"
          style="background: linear-gradient(56deg,#ffa482,#df88ff 34%,#9b86ff 67%,#aac8ff);"
        >
          <v-card-title>
            {{ $t('dqdj') }} VIP{{ myvipinfo.mylevel }}
          </v-card-title>
          <v-card-text>
            <div class="d-flex align-center justify-space-between body-2">
              <span class="white--text">VIP{{ myvipinfo.mylevel }}</span>
              <small>{{ myvipinfo.upgrade_progress }}%</small>
              <span class="white--text">VIP{{ myvipinfo.mylevel+1 }}</span>
            </div>
            <v-progress-linear
              rounded
              striped
              :value="myvipinfo.upgrade_progress"
              color="amber"
              height="5"
              class="my-1"
            />
            <div class="d-flex align-center justify-space-between">
              <small>{{ $t('j30tljck') }}</small>
              <small>
                <span class="yellow--text">R$ {{ myvipinfo.last30_deposit }}</span>
                / R$ {{ myvipinfo.upgrade_deposit }}
              </small>
            </div>

            <v-sheet
              class="mt-4 pa-3 white--text caption rounded-pill text-center"
            >
              {{ $t('rgysjd') }} <strong class="yellow--text">VIP{{ myvipinfo.mylevel+1 }}</strong> , {{ $t('nxyzck') }} <strong class="yellow--text">R$ {{ myvipinfo.upgrade_deposit - myvipinfo.last30_deposit }} </strong>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="6"
        md="2"
      >
        <v-card
          flat
          class="text-center rounded-lg"
          style="background: linear-gradient(46deg, rgb(230 165 255), rgb(254 220 251) 43%, rgb(219 195 250) 77%, rgb(246 209 255));"
        >
          <v-card-subtitle class="purple--text text--lighten-1">
            {{ $t('sxsjj') }}
            <v-btn
              icon
              small
              @click="showtip(1)"
            >
              <v-icon
                small
                color="grey"
              >
                mdi-tooltip-question-outline
              </v-icon>
            </v-btn>
          </v-card-subtitle>
          <div class="py-md-2">
            <v-img
              contain
              height="100"
              class="overflow-visible"
              src="../assets/img/bonus-box-closed.png"
            >
              <div
                v-if="hour3disabled"
                class="mt-n4"
              >
                <small class="d-block grey--text">{{ $t('daojishi') }}</small>
                <v-chip
                  dark
                  color="deep-orange"
                >
                  <v-icon left>
                    mdi-clock-time-twelve-outline mdi-spin
                  </v-icon>
                  {{ daojishi }}
                </v-chip>
              </div>
            </v-img>
          </div>
          <v-card-actions>
            <v-btn
              block
              small
              light
              color="primary"
              :loading="hour3loading"
              :disabled="hour3disabled || meminfo.id==0"
              @click="hour3award"
            >
              {{ $t('lingqu') }} R$ {{ myvipinfo.hour3_bonus_amount }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        md="2"
      >
        <v-card
          flat
          class="text-center rounded-lg"
          style="background: linear-gradient(46deg, rgb(255 199 116), rgb(246 255 204) 43%, rgb(248 234 193) 77%, rgb(255 192 109));"
        >
          <v-card-subtitle class="orange--text">
            {{ $t('cxg') }}
            <v-btn
              icon
              small
              @click="showtip(2)"
            >
              <v-icon
                small
                color="grey"
              >
                mdi-tooltip-question-outline
              </v-icon>
            </v-btn>
          </v-card-subtitle>
          <div class="py-md-2">
            <v-img
              contain
              height="100"
              class="overflow-visible"
              src="../assets/img/bonus-pig.png"
            >
              <div class="mt-n2">
                <v-chip
                  small
                  dark
                  color="orange"
                >
                  VIP{{ myvipinfo.mylevel }} BOOST +{{ myvipinfo.boost_ratio }}%
                </v-chip>
              </div>
              <v-chip
                small
                dark
                color="deep-orange"
                class="overflow-visible mt-n4"
              >
                <v-icon
                  left
                  small
                >
                  mdi-arrow-up
                </v-icon>
                R$ {{ myvipinfo.pig_append_bonus }}
              </v-chip>
            </v-img>
          </div>
          <v-card-actions>
            <v-btn
              block
              small
              light
              color="primary"
              :loading="pigloading"
              :disabled="meminfo.id==0 || parseFloat(myvipinfo.pig_total_bonus)==0"
              @click="pigaward"
            >
              <v-badge
                overlap
                dark
                color="green lighten-4 green--text"
                offset-x="50"
                offset-y="-1"
                :content="'+ R$'+myvipinfo.pig_append_bonus"
                label=""
              >
                {{ pigbtntxt }} R$ {{ myvipinfo.pig_base_bonus }}
              </v-badge>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        md="2"
      >
        <v-card
          flat
          class="text-center rounded-lg"
          style="background: linear-gradient(46deg, rgb(121, 199, 124), rgb(171, 247, 159) 43%, rgb(221, 248, 193) 77%, rgb(255, 242, 109));"
        >
          <v-card-subtitle class="green--text text--lighten-1 px-0">
            {{ $t('rfsjj') }}
            <v-btn
              icon
              small
              @click="showtip(3)"
            >
              <v-icon
                small
                color="grey"
              >
                mdi-tooltip-question-outline
              </v-icon>
            </v-btn>
          </v-card-subtitle>
          <div class="py-md-2">
            <v-img
              contain
              height="100"
              src="../assets/img/bonus-cashback.png"
            />
          </div>
          <v-card-actions>
            <v-btn
              block
              small
              light
              color="primary"
              :loading="rebateloading"
              :disabled="rebatedisabled || meminfo.id==0"
              @click="receive_rebate"
            >
              {{ $t('lingqu') }} R$ {{ myvipinfo.unreceive_rebate }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="6"
        md="2"
      >
        <v-card
          flat
          class="text-center rounded-lg"
          style="background: linear-gradient(46deg, rgb(169 158 248), rgb(192 222 254) 43%, rgb(227 240 254) 77%, rgb(175 215 255));"
        >
          <v-card-subtitle class="indigo--text text--lighten-1 px-0">
            {{ $t('zckjj') }}
            <v-btn
              icon
              small
              @click="showtip(4)"
            >
              <v-icon
                small
                color="grey"
              >
                mdi-tooltip-question-outline
              </v-icon>
            </v-btn>
          </v-card-subtitle>
          <div class="py-md-2">
            <v-img
              contain
              height="100"
              src="../assets/img/bonus-weekly.png"
            />
          </div>
          <v-card-actions>
            <v-btn
              block
              small
              color="primary"
              :disabled="meminfo.id==0"
              @click="showweek"
            >
              {{ $t('lingqu') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col
        cols="12"
      >
        <v-subheader class="font-weight-bold">
          {{ $t('qbvipdj') }}
        </v-subheader>

        <template>
          <v-card
            flat
          >
            <v-tabs
              v-model="tab"
              centered
              center-active
              dark
              icons-and-text
              show-arrows
              background-color="deep-purple accent-4"
              color="yellow"
              class="rounded-b-0"
            >
              <v-tab
                v-for="(item, index) in vipSlides"
                :key="'v_'+index"
                :href="'#tab-'+item.level"
              >
                VIP {{ item.level }}
                <img
                  width="40"
                  class="mb-0"
                  :src="require('../assets/img/icons/level-'+item.level+'.svg')"
                >
              </v-tab>

              <v-tab-item
                v-for="(item, index) in vipSlides"
                :key="'vv_'+index"
                :value="'tab-' + item.level"
                class="box_bg rounded-b"
              >
                <v-list-item
                  three-line
                  class="nav_bg py-2"
                >
                  <v-list-item-avatar
                    size="60"
                  >
                    <img :src="require('../assets/img/icons/level-'+item.level+'.svg')">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('dengjiyaoqiu') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('sstckxdd') }}</v-list-item-subtitle>
                    <strong class="mb-2 orange--text">R$ {{ item.last30day }}</strong>
                    <div>
                      <v-chip
                        v-if="item.status=='current'"
                        small
                        dark
                        color="deep-purple accent-4"
                        text-color="yellow"
                      >
                        <v-icon
                          left
                          small
                        >
                          mdi-check-decagram
                        </v-icon>
                        {{ $t('dqdj') }}
                      </v-chip>
                      <v-chip
                        v-if="item.status=='unlock'"
                        small
                        dark
                        color="deep-purple lighten-3"
                      >
                        <v-icon
                          left
                          small
                        >
                          mdi-lock-open-variant
                        </v-icon>
                        {{ $t('yidacheng') }}
                      </v-chip>
                      <v-chip
                        v-if="item.status=='locked'"
                        small
                      >
                        <v-icon
                          left
                          small
                        >
                          mdi-lock
                        </v-icon>
                        {{ $t('daijiesuo') }}
                      </v-chip>
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-subheader>{{ $t('quanyi') }}</v-subheader>
                <v-row
                  no-gutters
                >
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon large>
                          mdi-clock-time-nine
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle>{{ $t('sxsjj') }}</v-list-item-subtitle>
                        <v-list-item-title>R$ {{ item.hour3award }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon large>
                          mdi-piggy-bank
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle>{{ $t('cxg') }}</v-list-item-subtitle>
                        <v-list-item-title>+ {{ item.pigaward }}% Boost</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon large>
                          mdi-calendar-range
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle>{{ $t('zckjj_sub') }}</v-list-item-subtitle>
                        <v-list-item-title>R$ {{ item.weekdeposit }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-list-item>
                      <v-list-item-avatar>
                        <v-icon large>
                          mdi-cash-refund
                        </v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-subtitle>{{ $t('rfsjj') }}</v-list-item-subtitle>
                        <v-list-item-title>
                          <small>{{ $t('laohuji') }}: </small>
                          {{ item.daily_slots }}%
                        </v-list-item-title>
                        <v-list-item-title>
                          <small>{{ $t('zhenren') }}: </small>
                          {{ item.daily_live }}%
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-dialog
      v-model="claim"
      max-width="300"
      content-class="elevation-0"
    >
      <v-sheet
        color="transparent"
        class="claim-bg"
      >
        <v-row
          no-gutters
          justify="center"
          align="center"
          style="height: 300px;"
        >
          <v-col>
            <v-img
              contain
              position="center"
              width="180"
              class="mx-auto d-flex align-center justify-center text-center"
              src="../assets/img/bonus-box-open.png"
            >
              <div class="text-h6 font-weight-bold deep-orange--text mt-n4">
                + R$ {{ reward }}
              </div>
            </v-img>
          </v-col>
        </v-row>
      </v-sheet>
      <div class="text-center mt-n5">
        <v-btn
          fab
          depressed
          small
          class="opacity-5"
          @click="claimclose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-dialog>

    <v-dialog
      v-model="claim_piggy"
      max-width="300"
      content-class="elevation-0"
    >
      <v-sheet
        color="transparent"
        class="claim-bg"
      >
        <v-row
          no-gutters
          justify="center"
          align="center"
          style="height: 300px;"
        >
          <v-col>
            <v-img
              contain
              position="center"
              width="150"
              class="mx-auto d-flex align-center justify-center text-center"
              src="../assets/img/bonus-pig.png"
            >
              <v-chip
                light
                color="yellow"
                text-color="deep-orange"
                class="mb-n8"
              >
                + R$ {{ reward }}
              </v-chip>
            </v-img>
          </v-col>
        </v-row>
      </v-sheet>
      <div class="text-center mt-n5">
        <v-btn
          fab
          depressed
          small
          class="opacity-5"
          @click="claim_piggyclose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-dialog>

    <v-dialog
      v-model="claim_cashback"
      max-width="300"
      content-class="elevation-0"
    >
      <v-sheet
        color="transparent"
        class="claim-bg"
      >
        <v-row
          no-gutters
          justify="center"
          align="center"
          style="height: 300px;"
        >
          <v-col>
            <v-img
              contain
              position="center"
              width="150"
              class="mx-auto d-flex align-center justify-center text-center"
              src="../assets/img/bonus-cashback.png"
            >
              <v-chip
                light
                color="white"
                text-color="green"
              >
                + R$ {{ reward }}
              </v-chip>
            </v-img>
          </v-col>
        </v-row>
      </v-sheet>
      <div class="text-center mt-n5">
        <v-btn
          fab
          depressed
          small
          class="opacity-5"
          @click="claim_cashbackclose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
    </v-dialog>

    <v-dialog
      v-model="weekbonus"
      max-width="550"
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ $t('zckjj') }}
          <v-spacer />
          <v-btn
            icon
            @click="weekbonus = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-sheet class="text-caption opacity-5 pa-3 text-center nav_bg">
          {{ $t('szlj') }}:
          
          <div
            v-if="!achieved"
            class="red--text"
          >
            <v-icon
              small
              color="red"
              class="mr-1"
            >
              mdi-emoticon-sad
            </v-icon>
            {{ $t('weiman') }} R$ 500
          </div>
          <div v-if="achieved">
            <v-icon
              small
              class="mr-1"
            >
              mdi-check-circle
            </v-icon>
            {{ $t('yiman') }} R$ 500
          </div>
        </v-sheet>

        <v-simple-table
          class="nav_bg"
        >
          <thead>
            <tr>
              <th>{{ $t('riqi') }}</th>
              <th class="text-center">
                {{ $t('jiangjin') }}
              </th>
              <th class="text-center">
                {{ $t('zhuangtai') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('zhouyi') }}</td>
              <td class="text-center">
                <strong class="orange--text">R$ {{ promoconf.daily_bonus }}</strong>
              </td>
              <td class="text-center">
                <span
                  v-if="weekds[0]?.status=='received'"
                  class="text-caption primary--text"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-flag</v-icon>
                  {{ $t('yilingqu') }}
                </span>
                <span
                  v-if="weekds[0]?.status=='unreceived'"
                  class="text-caption grey--text"
                >
                  <v-icon
                    small
                    color="grey"
                  >mdi-emoticon-sad</v-icon>
                  {{ $t('weilingqu') }}
                </span>
                <v-btn
                  v-if="weekds[0]?.status=='available'"
                  depressed
                  small
                  color="primary"
                  @click="weeklyreceive"
                >
                  {{ $t('lingqu') }}
                </v-btn>
                <v-btn
                  v-if="weekds[0]?.status=='notup'"
                  depressed
                  small
                  color="primary"
                  disabled
                >
                  {{ $t('lingqu') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('zhouer') }}</td>
              <td class="text-center">
                <strong class="orange--text">R$ {{ promoconf.daily_bonus }}</strong>
              </td>
              <td class="text-center">
                <span
                  v-if="weekds[1]?.status=='received'"
                  class="text-caption primary--text"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-flag</v-icon>
                  {{ $t('yilingqu') }}
                </span>
                <span
                  v-if="weekds[1]?.status=='unreceived'"
                  class="text-caption grey--text"
                >
                  <v-icon
                    small
                    color="grey"
                  >mdi-emoticon-sad</v-icon>
                  {{ $t('weilingqu') }}
                </span>
                <v-btn
                  v-if="weekds[1]?.status=='available'"
                  depressed
                  small
                  color="primary"
                  @click="weeklyreceive"
                >
                  {{ $t('lingqu') }}
                </v-btn>
                <v-btn
                  v-if="weekds[1]?.status=='notup'"
                  depressed
                  small
                  color="primary"
                  disabled
                >
                  {{ $t('lingqu') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('zhousan') }}</td>
              <td class="text-center">
                <strong class="orange--text">R$ {{ promoconf.daily_bonus }}</strong>
              </td>
              <td class="text-center">
                <span
                  v-if="weekds[2]?.status=='received'"
                  class="text-caption primary--text"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-flag</v-icon>
                  {{ $t('yilingqu') }}
                </span>
                <span
                  v-if="weekds[2]?.status=='unreceived'"
                  class="text-caption grey--text"
                >
                  <v-icon
                    small
                    color="grey"
                  >mdi-emoticon-sad</v-icon>
                  {{ $t('weilingqu') }}
                </span>
                <v-btn
                  v-if="weekds[2]?.status=='available'"
                  depressed
                  small
                  color="primary"
                  @click="weeklyreceive"
                >
                  {{ $t('lingqu') }}
                </v-btn>
                <v-btn
                  v-if="weekds[2]?.status=='notup'"
                  depressed
                  small
                  color="primary"
                  disabled
                >
                  {{ $t('lingqu') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('zhousi') }}</td>
              <td class="text-center">
                <strong class="orange--text">R$ {{ promoconf.daily_bonus }}</strong>
                <div class="mt-n1">
                  <v-chip
                    x-small
                    outlined
                    dark
                    color="primary"
                  >
                    x 2
                  </v-chip>
                </div>
              </td>
              <td class="text-center">
                <span
                  v-if="weekds[3]?.status=='received'"
                  class="text-caption primary--text"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-flag</v-icon>
                  {{ $t('yilingqu') }}
                </span>
                <span
                  v-if="weekds[3]?.status=='unreceived'"
                  class="text-caption grey--text"
                >
                  <v-icon
                    small
                    color="grey"
                  >mdi-emoticon-sad</v-icon>
                  {{ $t('weilingqu') }}
                </span>
                <v-btn
                  v-if="weekds[3]?.status=='available'"
                  depressed
                  small
                  color="primary"
                  @click="weeklyreceive"
                >
                  {{ $t('lingqu') }}
                </v-btn>
                <v-btn
                  v-if="weekds[3]?.status=='notup'"
                  depressed
                  small
                  color="primary"
                  disabled
                >
                  {{ $t('lingqu') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('zhouwu') }}</td>
              <td class="text-center">
                <strong class="orange--text">R$ {{ promoconf.daily_bonus }}</strong>
              </td>
              <td class="text-center">
                <span
                  v-if="weekds[4]?.status=='received'"
                  class="text-caption primary--text"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-flag</v-icon>
                  {{ $t('yilingqu') }}
                </span>
                <span
                  v-if="weekds[4]?.status=='unreceived'"
                  class="text-caption grey--text"
                >
                  <v-icon
                    small
                    color="grey"
                  >mdi-emoticon-sad</v-icon>
                  {{ $t('weilingqu') }}
                </span>
                <v-btn
                  v-if="weekds[4]?.status=='available'"
                  depressed
                  small
                  color="primary"
                  @click="weeklyreceive"
                >
                  {{ $t('lingqu') }}
                </v-btn>
                <v-btn
                  v-if="weekds[4]?.status=='notup'"
                  depressed
                  small
                  color="primary"
                  disabled
                >
                  {{ $t('lingqu') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('zhouliu') }}</td>
              <td class="text-center">
                <strong class="orange--text">R$ {{ promoconf.daily_bonus }}</strong>
              </td>
              <td class="text-center">
                <span
                  v-if="weekds[5]?.status=='received'"
                  class="text-caption primary--text"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-flag</v-icon>
                  {{ $t('yilingqu') }}
                </span>
                <span
                  v-if="weekds[5]?.status=='unreceived'"
                  class="text-caption grey--text"
                >
                  <v-icon
                    small
                    color="grey"
                  >mdi-emoticon-sad</v-icon>
                  {{ $t('weilingqu') }}
                </span>
                <v-btn
                  v-if="weekds[5]?.status=='available'"
                  depressed
                  small
                  color="primary"
                  @click="weeklyreceive"
                >
                  {{ $t('lingqu') }}
                </v-btn>
                <v-btn
                  v-if="weekds[5]?.status=='notup'"
                  depressed
                  small
                  color="primary"
                  disabled
                >
                  {{ $t('lingqu') }}
                </v-btn>
              </td>
            </tr>
            <tr>
              <td>{{ $t('zhouri') }}</td>
              <td class="text-center">
                <strong class="orange--text">R$ {{ promoconf.daily_bonus }}</strong>
                <div class="mt-n1">
                  <v-chip
                    v-if="receive_times>=5"
                    x-small
                    outlined
                    dark
                    color="primary"
                  >
                    + {{ promoconf.sunday_extra_bonus }}
                  </v-chip>
                  <v-chip
                    v-else
                    x-small
                    outlined
                    dark
                    color="grey"
                  >
                    + {{ promoconf.sunday_extra_bonus }}
                  </v-chip>
                </div>
              </td>
              <td class="text-center">
                <span
                  v-if="weekds[6]?.status=='received'"
                  class="text-caption primary--text"
                >
                  <v-icon
                    small
                    color="primary"
                  >mdi-flag</v-icon>
                  {{ $t('yilingqu') }}
                </span>
                <span
                  v-if="weekds[6]?.status=='unreceived'"
                  class="text-caption grey--text"
                >
                  <v-icon
                    small
                    color="grey"
                  >mdi-emoticon-sad</v-icon>
                  {{ $t('weilingqu') }}
                </span>
                <v-btn
                  v-if="weekds[6]?.status=='available'"
                  depressed
                  small
                  color="primary"
                  @click="weeklyreceive"
                >
                  {{ $t('lingqu') }}
                </v-btn>
                <v-btn
                  v-if="weekds[6]?.status=='notup'"
                  depressed
                  small
                  color="primary"
                  disabled
                >
                  {{ $t('lingqu') }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-card-text class="text--disabled">
          <v-subheader class="px-0">
            {{ $t('jjsm') }}：
          </v-subheader>
          <ul>
            <li>{{ $t('zckjj1') }}</li>
            <li>{{ $t('zckjj2') }}</li>
            <li>{{ $t('zckjj3') }}</li>
            <li>{{ $t('zckjj4') }}</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="tipDialog"
      max-width="300"
      scrollable
    >
      <v-card class="box_bg">
        <v-card-title class="text-subtitle-1">
          {{ tiptitle }}
        </v-card-title>
        <v-card-text v-html="tipcontent" />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="tipDialog = false"
          >
            {{ $t('zdl') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-overlay :value="showprogress">
      <v-progress-circular
        indeterminate
      />
    </v-overlay>
  </v-container>
</template>


<script>
import store from "../store/"
import confetti from "canvas-confetti"
export default {
  name: 'Vip',
  components: {

  },
  data: () => ({
    tipDialog: false,
    claim: false,
    claim_piggy: false,
    claim_cashback: false,
    weekbonus: false,
    reward: 0,
    tiptitle: '',
    tipcontent: '',
    vipSlides: [
      {
        "level": 0
      },
      {
        "level": 1
      },
      {
        "level": 2
      },
      {
        "level": 3
      },
      {
        "level": 4
      },
      {
        "level": 5
      },
      {
        "level": 6
      },
      {
        "level": 7
      },
      {
        "level": 8
      },
      {
        "level": 9
      },
      {
        "level": 10
      }
    ],
    meminfo:{
      id:0
    },
    myvipinfo:{
      mylevel: 0,
      last30_deposit: 0,
      upgrade_deposit: 0,
      hour3_bonus_amount: 0,
      hour3_lasttime: 0,
      boost_ratio: 0,
      pig_base_bonus: 0,
      pig_lasttime: 0,
      slots_rebate_ratio: 0,
      live_rebate_ratio: 0,
      upgrade_progress: 0,
      pig_append_bonus: 0,
      pig_total_bonus: 0,
      unreceive_rebate: 0
    },
    daojishi: '',
    hour3loading: false,
    pigloading: false,
    rebateloading: false,
    countdownhour3: 1,
    hour3timer: null,
    tab: '',
    hour3content: '',
    pigcontent: '',
    cashbackcontent: '',
    weeklycontent: '',
    showprogress: false,
    achieved: false,
    promoconf: {},
    weekds: [],
    receive_times: 0
  }),
  
  computed: {
    hour3disabled () {
      return this.countdownhour3>0 || parseFloat(this.myvipinfo.hour3_bonus_amount)==0
    },
    rebatedisabled () {
      return parseFloat(this.myvipinfo.unreceive_rebate)==0
    },
    pigbtntxt () {
      if(this.meminfo.level<2 || this.myvipinfo.pig_lasttime>0){
        return this.$t('suoding')
      }
      return this.$t('lingqu')
    }
  },
  watch: {
    showprogress (val) {
      val && setTimeout(() => {
        this.showprogress = false
      }, 5000)
    },
  },
  created() {
    this.viplevels()
    this.tab = 'tab-'+this.$store.state.current_level
  },
  mounted() {
    this.$nextTick(() => {

    })
    if(this.getStorage('member_id')){
      this.getmeminfo()
    }
    this.vippromoinfo()
  },
  beforeDestroy() {

  },
  methods: {
    viplevels() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.viplevels(paramObj).then((response) => {
        if(response.code==200){
          this.vipSlides = response.data
        }
      })
    },
    getmeminfo() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.getmeminfo(paramObj).then((response) => {
        if(response.code==200){
          this.meminfo = response.data
          this.myvip()
          this.balance = response.data.total_balance
          store.commit('balance', this.balance)
        }
      })
    },
    myvip() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.myvip(paramObj).then((response) => {
        if(response.code==200){
          this.myvipinfo = response.data
          store.commit('current_level', response.data.mylevel)
          this.countdownhour3 = parseInt(response.data.hour3_lasttime)
          this.hour3countdown()
          this.tab = 'tab-'+response.data.mylevel
        }
      })
    },
    hour3award() {
      this.hour3loading = true
      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.hour3award(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.reward = this.myvipinfo.hour3_bonus_amount
          this.claim = true
          confetti({
            particleCount: 150,
            spread: 60
          })
          this.getmeminfo()
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
      this.hour3loading = false
    },
    pigaward() {
      if(this.meminfo.level<2){
        this.tiptitle = this.$t('wxts')
        this.tipcontent = this.$t('ddcnlq')
        this.tipDialog = true
        return false
      }
      if(this.myvipinfo.pig_lasttime>0){
        this.tiptitle = this.$t('wxts')
        this.tipcontent = this.$t('mzznlqyc')
        this.tipDialog = true
        return false
      }
      this.pigloading = true
      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.pigaward(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.reward = this.myvipinfo.pig_total_bonus
          this.claim_piggy = true
          confetti({
            particleCount: 150,
            spread: 60
          })
          this.getmeminfo()
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
      this.pigloading = false
    },
    receive_rebate() {
      this.rebateloading = true
      this.showprogress = true
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.receive_rebate(paramObj).then((response) => {
        this.showprogress = false
        if(response.code==200){
          this.reward = this.myvipinfo.unreceive_rebate
          this.claim_cashback = true
          confetti({
            particleCount: 150,
            spread: 60
          })
          this.getmeminfo()
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
      this.rebateloading = false
    },
    vippromoinfo() {
      let paramObj = {
        
      }
      this.$server.vippromoinfo(paramObj).then((response) => {
        if(response.code==200){
          this.hour3content = response.data[5].promo_content
          this.pigcontent = response.data[6].promo_content
          this.cashbackcontent = response.data[8].promo_content
          this.weeklycontent = response.data[7].promo_content
        }else{
          
        }
      })
    },
    showweek() {
      this.weeklyinfo()
      this.weekbonus = true
    },
    weeklyinfo() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.weeklyinfo(paramObj).then((response) => {
        if(response.code==200){
          this.achieved = response.data.achieved
          this.promoconf = response.data.promoconf
          this.weekds = response.data.weekds
          this.receive_times = response.data.receive_times
        }else{
          
        }
      })
    },
    weeklyreceive() {
      let paramObj = {
        member_id: this.getStorage('member_id'),
      }
      this.$server.weeklyreceive(paramObj).then((response) => {
        if(response.code==200){
          this.weeklyinfo()
          confetti({
            particleCount: 150,
            spread: 60
          })
        }else{
          
        }
      })
    },
    claimclose() {
      this.claim = false
      this.reward = 0
    },
    claim_piggyclose() {
      this.claim_piggy = false
      this.reward = 0
    },
    claim_cashbackclose() {
      this.claim_cashback = false
      this.reward = 0
    },
    hour3countdown() {
      this.hour3timer = setInterval(() => {
        if (this.countdownhour3 > 0) {
          this.countdownhour3--
          let hours = Math.floor(this.countdownhour3 / 3600)
          let minutes = Math.floor(this.countdownhour3 / 60) % 60
          let seconds = this.countdownhour3 % 60
          this.daojishi = hours+':'+minutes+':'+seconds
          store.commit('hour3reddot', false)
        }else{
          this.daojishi = ''
          clearInterval(this.hour3timer)
          this.hour3timer = null
          store.commit('hour3reddot', true)
        }
      }, 1000)
    },
    showtip(id) {
      switch(id)
      {
        case 1:
            this.tiptitle = this.$t('sxsjj')
            this.tipcontent = this.hour3content
            break;
        case 2:
            this.tiptitle = this.$t('cxg')
            this.tipcontent = this.pigcontent
            break;
        case 3:
            this.tiptitle = this.$t('rfsjj')
            this.tipcontent = this.cashbackcontent
            break;
        case 4:
            this.tiptitle = this.$t('zckjj')
            this.tipcontent = this.weeklycontent
            break;
        default:

      }
      this.tipDialog = true
    }
  }

}
</script>

<style>
  .claim-bg {
    position: relative;
    overflow: hidden;
  }
  .claim-bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../assets/img/bonus-claim-bg.png"); 
    background-position: center center; 
    background-repeat: no-repeat; 
    background-size: cover;
    animation: rotateAnimation 10s linear infinite;
  }
  @keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>